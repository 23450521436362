const EventCopyControllers = require('./copy');
const EventBulkControllers = require('./bulk');

angular.module('backstage.controllers.events', [EventCopyControllers, EventBulkControllers])
    .controller('EventSettingsCtrl', require('./EventSettingsCtrl'))
    .controller('ManageAppAssetsCtrl', require('./ManageAppAssetsCtrl'))
    .controller('QuickAddEventTeamMemberCtrl', require('./QuickAddEventTeamMemberCtrl'))
    // legacy
    .controller('EventCtrl', require('./EventDashboardCtrl'));

