"use strict";

module.exports = /* @ngInject */["$routeParams", "$scope", "eventService", function ($routeParams, $scope, eventService) {
  // load an existing event into the scope if an eventId parameter was provided to the route (as with instance creation)
  eventService.decorateScope($routeParams.eventId);
  return $scope.changeableAssetsByCategory = {
    icons: ['gif.loading/file.gif'],
    sender_identities: ['senderimg.spotman/file.png'],
    banners: ['banner.home_landscape_ipad/file.png', 'banner.home_portrait_ipad/file.png', 'banner.home_portrait_iphone/file.png', 'banner.surveycomplete_landscape_ipad/file.png', 'banner.surveycomplete_portrait_ipad/file.png', 'banner.surveycomplete_portrait_iphone/file.png', 'banner.uncompletedsurvey_landscape_ipad/file.png', 'banner.uncompletedsurvey_portrait_ipad/file.png', 'banner.uncompletedsurvey_portrait_iphone/file.png']
  };
}];